<template>
	<div>
		<!-- 内容 -->
		<div class="orderdetailsbox">
			<div class="commnetbox d-flex">
				<div class="commnetboxleft">
					<div class="commentboxlist">
						<div class="orderdetailstitle setitle clearfloat"><h5>买家信息</h5></div>
						<div class="commuser">
							<div class="commuserup">
								<div class="commuserupimg"><img :src="contentdetail.user?.avatar_url" alt="" :onerror="errorImg"></div>
								<p class="commuserupname">{{contentdetail.user?.nickname}}</p>
							</div>
							<div class="commuserbut">
								<div class="commuserbutlist clearfloat">
									<span>商品</span>
									<div class="commuserbutright">
										<a href="javascript:void(0);">{{contentdetail.goods?.goods_name}}</a>
									</div>
								</div>
								<div class="commuserbutlist clearfloat">
									<span>订单编号</span>
									<div class="commuserbutright">
										<p>{{contentdetail.order_no}}</p>
									</div>
								</div>
								<div class="commuserbutlist clearfloat">
									<span>评论时间</span>
									<div class="commuserbutright">
										<p>{{contentdetail.created_at}}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="commentboxlist">
						<div class="orderdetailstitle setitle clearfloat"><h5>历史评价</h5></div>
						<div class="historycon">
							<div class="historyconter" v-if="contentdetail.history && contentdetail.history.length">
								<div class="historylist clearfloat" v-for="(item,index) in contentdetail.history" :key="index">
									<div class="historylistup clearfloat d-flex align-items-center">
										<div class="historyimg">
											<img :src="item.detail.pic" alt="" :onerror="errorImg">
										</div>
										<div class="historytext">
											<p>{{item.detail.goods_name}}</p>
										</div>
									</div>
									<div class="historypf clearfloat">
										<span>评分</span>
										<div class="historypfright">
											<el-rate v-model="item.score_star" disabled text-color="#ff9900"></el-rate>
										</div>
									</div>
									<div class="historylast">
										<p>{{item.content}}</p>
										<ul class="clearfloat">
											<li v-for="(ite,ind) in item.pic_list" :key="ind"><img :src="ite" alt="" :onerror="errorImg"></li>
										</ul>
									</div>
								</div>
							</div>
							<!-- 暂无历史评价显示 -->
							<template v-else>
								<div  v-if="datatype" class="pitera"><img src="../../assets/images/home-empty.png" alt=""> <p>暂无历史评价</p></div>
							</template>
						</div>
					</div>
				</div>
				<div class="commnetboxright">
					<div class="orderdetailstitle setitle clearfloat"><h5>评价内容</h5></div>
					<div class="commentboxcon">
						<div class="commentpf clearfloat">
							<span>评分</span>
							<div class="historypfright">
								<el-rate v-model="contentdetail.score_star" disabled text-color="#ff9900"></el-rate>
							</div>
						</div>
						<div class="commentboxconter">
							<div class="commentitle clearfloat">
								<h5>首次评价:{{contentdetail.updated_at}}</h5>
								<div class="commentitleright">
									<div class="processtype">
										<p v-if="contentdetail.is_review == 0">未审核</p>
										<p class="yiprocess" v-if="contentdetail.is_review == 1">已审核</p>
										<p class="zprocess" v-if="contentdetail.is_review == 2">审核中</p>
										<p class="weiprocess"  v-if="contentdetail.is_review == 3">审核不通过</p>
									</div>
								</div>
							</div>
							<div class="commentconter">
								<div class="discuss">
									<p>{{contentdetail.content}}</p>
									<ul class="clearfloat">
										<li v-for="(item,index) in contentdetail.pic_list" :key="index"><img :src="item" alt="" :onerror="errorImg"></li>
									</ul>
								</div>
								<div class="commentreply">
									<el-input type="textarea" v-model.trim="conmmentext" maxlength="255" show-word-limit resize="none"
										class="textarea-box" placeholder="回复内容(最多输入255字)"></el-input>
									<div class="commentreplylast clearfloat" v-if="hasPerm(['order.comment.reply'])">
										<button @click="detailreply(contentdetail.id)">回复</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				errorImg:'this.src="' + require("../../assets/images/lodingsb.svg") + '"', //图片加载失败
				id: this.$route.query.id,
				pfvalue:3,
				discussvalue:5,
				contentdetail: {},
				datatype: false,
				conmmentext: '',
				idlist:[],
			};
		},
		created() {
		},
		mounted() {
			this.commentdetailapi(this.id);
		},
		methods: {
			commentdetailapi(id){
				this.$get(this.$apis.commentdetail + id).then(res => {
					if (res.code == 200) {
						this.contentdetail = res.data;
						this.datatype = true;
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			},
			detailreply(id){
				this.idlist.push(id)
				let data = {
					content:this.conmmentext,
					id: this.idlist
				};
				this.$put(this.$apis.commentreply,data).then(res => {
					if (res.code == 200) {
						this.$message({
							showClose: true,
							message: '回复成功',
							type: 'success',
							offset: 200,
							duration: 1000,
						});
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			},
		}
	};
</script>

<style scoped>
	@import url("css/order.css");
	.commentreply .el-textarea{width: 100% !important;}
</style>